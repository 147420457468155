const requestInterceptors = []
const responseInterceptors = []

const originalFetch = window.fetch

function useRequestInterceptor(interceptor) {
	requestInterceptors.push(interceptor)
}

function useResponseInterceptor(interceptor) {
	responseInterceptors.push(interceptor)
}

async function customFetch(input, init = {}) {
	// Apply request interceptors
	requestInterceptors.forEach((interceptor) => {
		const result = interceptor(input, init)
		if (result) {
			input = result.input || input
			init = result.init || init
		}
	})

	// Add the CSRF token to the request headers
	init.headers = {
		...init.headers,
		"X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
	}

	try {
		let response = await originalFetch(input, init)

		// Apply response interceptors
		for (const interceptor of responseInterceptors) {
			response = (await interceptor(response, input, init)) || response
		}

		return response // Return the response for non-419 cases
	} catch (error) {
		throw error // Rethrow the error to handle it elsewhere
	}
}

// install CSRF token expire response interceptor
useResponseInterceptor(async (response, input, init) => {
	if (response.status === 419) {
		// Fetch a new CSRF token
		try {
			const csrfResponse = await originalFetch("/csrf-token")
			if (!csrfResponse.ok) throw new Error("Failed to fetch CSRF token")
			const csrfToken = await csrfResponse.text()
			document.querySelector('meta[name="csrf-token"]').setAttribute("content", csrfToken)
		} catch (error) {
			const modalProvider = Alpine.$data(document.getElementById("modal-provider"))
			if (modalProvider)
				return modalProvider
					.openModal({
						type: "info",
						title: window.errorMessages.loggedOutTitle,
						message: window.errorMessages.loggedOutDescription,
						confirmBtn: "Login",
					})
					.then(() => {
						window.location.reload()
						return response
					})
		}

		return originalFetch(input, {
			...init,
			headers: {
				...init.headers,
				"X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
			},
		})
	}

	return response
})

// Override the global fetch function
window.fetch = customFetch

// Export the interceptor functions
export { useRequestInterceptor, useResponseInterceptor }
